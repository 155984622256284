<template>
 <div >

     <XModal name="x2" :resizable="true"  width='80%' height="80%" :draggable="true" >
       <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
         <div class='appHead'>
             <div style='display: inline-flex; color: #444; font-weight: bold; padding-left: 5pt; padding-top: 3pt; font-size: 10pt; float: left;'>
             <span v-if="headerData.dealId">Deal viewer</span><span v-else>Campaign viewer</span> 
             </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div v-if="ready" class='mainContent dontWrap'  >
                <div :style="getAppStyle()+'float: top; width: 100%;'" class="title">{{headerData.deal}} {{headerData.campaign}} 
                     <span style="padding-left: 10pt; padding-top: 1pt;" class="small">({{data.currency.code}}) in {{data.houseCurrency.code}}</span>
                </div>
             	
			    <div style='width: 100%; height: calc(75vH); overflow-y: auto;'>
			    	<div v-if="ready && data.monthBudgets" style='float: top; width: 100%;height: 100%; width: 100%;'>
			    		<div class="cellTitle head">Months</div>
			    	    <div class="cell head centered" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
			    	    	{{line.yearMonth}}
			    	    </div>
			    	    
			    	     <div class="row">
			    	       <div class="cellTitle">
			    	        Spots
			    	       </div>
			    	    
			    	       <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
			    	    	{{formatNZ(line.items)}}
			    	       </div>
			    	    </div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.itemsCumul)}}
				    	    </div>
			    	     </div>
						 
						 <div class="row">
 			    	       <div class="cellTitle gap5">
 			    	        GRP
 			    	       </div>
 			    	    
 			    	       <div class="cell right gap5" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
 			    	    	{{formatNZ(line.grpTotal)}}
 			    	       </div>
 			    	    </div>
						
						<div class="row">
 			    	       <div class="cellTitle indent">
 			    	        cumulative
 			    	       </div>
 			    	    
 			    	       <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
 			    	    	{{formatNZ(line.grpTotalCumul)}}
 			    	       </div>
 			    	    </div>
						
						<div class="row">
 			    	       <div class="cellTitle">
 			    	        CPP
 			    	       </div>
 			    	    
 			    	       <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
							<span v-if="line.grpTotal">
 			    	    	{{formatNZ(line.revenuePaid/line.grpTotal)}}
							</span>
							<span v-if="line.revenuePaid">
 			    	    	{{formatNZ(0)}}
							</span>
 			    	       </div>
 			    	    </div>
						
						<div class="row">
 			    	       <div class="cellTitle indent">
 			    	        cumulative
 			    	       </div>
 			    	    
 			    	       <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
							<span v-if="line.grpTotalCumul">
 			    	    	{{formatNZ(line.paidCumul/line.grpTotalCumul)}}
							</span>
							<span v-if="line.revenuePaid">
 			    	    	{{formatNZ(0)}}
							</span>
 			    	       </div>
 			    	    </div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle gap5">
				    	    Paid
				    	    </div>
				    	    <div class="cell right gap5" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenuePaid)}}
				    	    </div>
				    	</div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.paidCumul)}}
				    	    </div>
			    	     </div>
			    	     
			    	     <div class="row">
				    	    <div class="cellTitle">
				    	    Net1
				    	    </div>
				    	    <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenueNet1)}}
				    	    </div>
				    	</div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.net1Cumul)}}
				    	    </div>
			    	     </div>
			    	     
			    	     <div class="row">
				    	    <div class="cellTitle">
				    	    Net2
				    	    </div>
				    	    <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenueNet2)}}
				    	    </div>
				    	</div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.net2Cumul)}}
				    	    </div>
			    	     </div>
			    	     
			    	     <div class="row">
				    	    <div class="cellTitle">
				    	    Net3
				    	    </div>
				    	    <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenueNet3)}}
				    	    </div>
				    	</div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.net3Cumul)}}
				    	    </div>
			    	     </div>
			    	   
			    	    <div class="row">
				    	    <div class="cellTitle gap5">
				    	    Discounting
				    	    </div>
				    	    <div class="cell right gap5" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenueDiscounting)}}
				    	    </div>
				    	</div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.discountingCumul)}}
				    	    </div>
			    	    
			    	     </div>
			    	    
			    	    <div class="row gap5">
				    	    <div class="cellTitle gap5">
				    	    Bonus (Claim)
				    	    </div>
				    	    <div class="cell right gap5" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.bonusClaim)}}
				    	    </div>
				    	</div>
			    	   
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.bonusClaimCumul)}}
				    	    </div>
			    	     </div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle">
				    	    Bonus (Act.)
				    	    </div>
				    	    <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.revenueBonus)}}
				    	    </div>
				    	 </div>
			    	    
			    	    <div class="row">
				    	    <div class="cellTitle indent">
				    	    cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.bonusCumul)}}
				    	    </div>
				    	</div>
				    	
				    	<div class="row">
				    	    <div class="cellTitle">
				    	    &Delta;
				    	    </div>
				    	    <div class="cell right" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.bonusClaim-line.revenueBonus)}}
				    	    </div>
				    	</div>
				    	
				    	<div class="row">
				    	    <div class="cellTitle indent">
				    	    &Delta; cumulative
				    	    </div>
				    	    <div class="cell right cumulative" v-for="(line,idx) in data.monthBudgets" :key="'mb'+idx">
				    	    	{{formatNZ(line.bonusClaimCumul-line.bonusCumul)}}
				    	    </div>
				    	</div>
			    	   
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->
            </div> <!-- float: left -->
            </slot>
			</div> <!-- mainContent -->
	      
	    </div> <!-- modal-body -->
	    
	
	  </XModal> <!-- modal-container -->  	  
	
	<ContextMenu ref="menu" offsetY=120>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem >
          What ever
        </ContextMenuItem>
        <ContextMenuItem >
          What else
        </ContextMenuItem>
        <ContextMenuSep/>
        
       </template>
    </ContextMenu>
 
 </div>
</template>

<script>
import {HTTP, fwAPI, dealReportAPI, bngAPI, setReload, showError, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle, initAppMode, isDarkMode } from '@/AppStyle.js';
import { format, formatNZ } from '@/Numbers.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';

import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW DEAL View',
  components : {
   ContextMenu,ContextMenuItem,ContextMenuSep
  },
  props: {

  },
  data () {
    return {
    	data: {},
    	headerData: {},
    	getAppStyle, format, formatNZ,
    	ready: false,
      }
  },
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
    
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
    open(data) { 
        
     	 this.headerData = data
     	 this.$modal.show('x2')
     	 this.loadData( this.headerData);
     },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
  
     identity(x) { return x },
    
     close() { this.$modal.hide('x2') },
     loadData( headerData)
     {
    	 let id = 0;
    	 let channelId = 0;
    	 let entity = 'Placement'
    	 if ( headerData.type === 'DEAL' )
    	 {
    		 id = headerData.dealId ;
    		 entity = 'Deal'
    	 }
    	 else if ( headerData.type === 'CAMPAIGN' )
    	 {
    		 id = headerData.campaignId ;
    		 entity = 'Campaign'
    	 }
    	 else if ( headerData.type === 'PLACEMENT' )
    	 {
    		 id = headerData.placementId ;
    		 entity = 'Placement'
    	 }
    	 else if ( headerData.type === 'PLACEMENT_MEDIA' )
    	 {
    		 id = headerData.placementId ;
    		 channelId = headerData.mediaId;
    		 entity = 'Placement'
    	 }
    	 let that = this;
    	 that.data = {};
 		 // alert( JSON.stringify(req)+"/"+this.fromDate)
   		 let dataLoad = new Promise(function (resolve, reject) {
             
        		HTTP.post( dealReportAPI+"/getDealCampaignMonthlyData/"+sessionStorage.tenantId
        				+"/"+sessionStorage.accessPointId
        				+"/"+sessionStorage.unitId
        				+"/"+sessionStorage.userId
        				+"/"+headerData.type
        				+"/"+id
        				+"/"+channelId
        				+"/"+0
        				)

         		 .then( response => 
                  {
                	
                  	that.data = response.data;
                  	let paidCumul = 0;
                  	let spotsCumul = 0;
					let grpTotalCumul = 0;
                  	let discountingCumul = 0;
                  	let bonusClaimCumul = 0;
                  	let bonusCumul = 0;
                  	let net1Cumul = 0;
                  	let net2Cumul = 0;
                  	let net3Cumul = 0;
                  	for ( let i in that.data.monthBudgets)
                 	{
                  		paidCumul += that.data.monthBudgets[i].revenuePaid;
                  		bonusCumul += that.data.monthBudgets[i].revenueBonus;
                  		spotsCumul += that.data.monthBudgets[i].items;
                  		net1Cumul += that.data.monthBudgets[i].revenueNet1;
                  		net2Cumul += that.data.monthBudgets[i].revenueNet2;
                  		net3Cumul += that.data.monthBudgets[i].revenueNet3;
						grpTotalCumul += that.data.monthBudgets[i].grpTotal;
                  		discountingCumul += that.data.monthBudgets[i].revenueDiscounting;
                 		that.data.monthBudgets[i].itemsCumul = spotsCumul;
                 		that.data.monthBudgets[i].paidCumul = paidCumul;
                 		that.data.monthBudgets[i].net1Cumul = net1Cumul;
                 		that.data.monthBudgets[i].net2Cumul = net2Cumul;
                 		that.data.monthBudgets[i].net3Cumul = net3Cumul;
                 		that.data.monthBudgets[i].discountingCumul = discountingCumul;
						that.data.monthBudgets[i].grpTotalCumul = grpTotalCumul;
                 		that.data.monthBudgets[i].bonusClaim = that.data.monthBudgets[i].revenueBonusClaim;
                 		
                 		bonusClaimCumul += that.data.monthBudgets[i].bonusClaim;
                 		that.data.monthBudgets[i].bonusClaimCumul = bonusClaimCumul;
                 		that.data.monthBudgets[i].bonusCumul = bonusCumul;
                 	}
                  	
                  	that.ready = true;
                     resolve (true);     
                     
                  }).catch(e => {
                 	    showError( that.$toast, "get INVOICE Input data", e);
                 	    
                         reject ();
                     });
 	      });
     },
  },
  watch: {

	
  },
  created() {
	this.$modal.show('x2');
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  
}
.title { 
  display: block;
  align-items: left;
  justify-content: left;
  font-size: 10pt; 
  padding-left: 5pt;
  width: 100% !important; 
}
.cell
{
	display: table-cell; 
	font-size: 9pt; 
	width: 50pt;
	height: 18pt;
	text-align: right;
	padding-top: 2pt;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
}
.cellTitle
{
	display: table-cell; 
	font-size: 9pt; 
	height: 18pt;
	width: 80pt !important;
	padding-top: 2pt;
	font-weight: bold;
	text-align: left;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
	background-color: #3b60c4;
	color: #fff;
}
.indent {
	padding-left: 5pt;
	font-style: italic;
	font-weight: normal;
}
.cumulative {
	font-style: italic;
	font-weight: normal;
}
.row
{
	display: table-row;
}
.row:hover
{
	background-color: #777;
	color: #fff;
}
.gap5 
{
	border-top: 2px solid #888;
}
.head
{
	font-weight: bold;
	background-color: #3b60c4;
	color: #fff;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.left {
  justify-content: left;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  x-overflow: hidden;
}

.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}

.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
    
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
